import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import * as headerStyles from '../styles/modules/header.module.scss';
import logo from "../assets/img/logo.png";

const Header = () => {
    
    console.log("headerStyles",headerStyles);

    const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            title
            url
          }
        }
      }
    `);
    
    return (
        <header className={headerStyles.header}>
            <div className="container">
                <div className="row">
                    <div className={headerStyles.wrapper}>
                        <div className="logo">
                            <a href={data.site.siteMetadata.url} title={data.site.siteMetadata.title}>
                                <img src={logo} alt={data.site.siteMetadata.title} />
                            </a>
                        </div>
                        <nav>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/blog/">Blog</a></li>
                                <li><a href="/contact/">Contact</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header